.App {
  text-align: left;
}

.App-header {
  padding: 8px;
}

.App-link {
  color: #61dafb;
}
td,
th {
  padding: 2px 6px !important;
}
tr:nth-child(even) {
  background-color: #efefef !important;
}
thead th {
  position: sticky;
  top: 0;
  background-color: #000000;
  color: #ffffff !important;
}
